/* src/Carousel.css */

.carousel-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
}

.carousel-slide img {
    width: 100%;
    height: auto;
}

.carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
}