/* src/Hero.css */

.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    /* background-color: ; */
    text-align: center;
    flex-wrap: wrap;
}

.hero-content {
    max-width: 600px;
    margin: 32px;
    text-align: left;
    line-height: 1.2;
}

.hero h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.hero p {
    font-size: 18px;
    margin-bottom: 40px;
}

.hero-button {
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 35px;
    font-size: 18px;
}
.hero-button:hover{
    background-color: rgba(1, 60, 174, 1);
    color:#fff;
}

.hero-image {
    max-width: 400px;
    margin: 20px;
}

.hero-image img {
    width: 100%;
    height: 60vh;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .hero h1 {
        font-size: 36px;
    }

    .hero p {
        font-size: 18px;
    }

    .hero-button {
        padding: 8px 16px;
    }
    .hero-image img{
            height: auto;
        }
    .hero-content{
        text-align: center;
    }
}