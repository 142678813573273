

@media (max-width: 600px) {
.navItem {
    margin: 12px;
  }
 
 
  .ContactContainer {
    flex-direction: column ;
    
  }
  .ProductDetail{
    flex: 1 1 calc(50% - 20px) ;
    font-size: 1.12rem;
  }
 

}

.logo img {
  max-width: 150px;
  margin-left: 42px;
  height: auto;
}
.navSection{
  padding: 40px;
}
.navItem {
  margin: 32px;
}

.ContactContainer{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  background-color:#fff;
  border: 1px solid;
  border-radius: 5px;
  padding: 15px;
 
}
.ProductDetail{
  flex: 1;
  max-width: 400px;
  padding: 20px;
  margin: 50px;
  box-sizing: border-box;
}
.ProductDetail h1{
  color: #000;
}
.ProductDetail>p{
  color: #000;
  padding-top: 20px;
  line-height: 1.5rem;
  
}
.homeProductImg {
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .16);
  margin: 10px; 
  padding: 10px;
}
.homeProductImg img{
  height: 250px;
  max-width: 100%;
}
.caption{
  padding: 60px 20px;
  text-align: center;
  
}
.AboutUsContainer{
  display: grid;
  grid-template-columns: auto minmax(200px, 500px);
  grid-template-rows: repeat(4, 1fr);
  grid-auto-rows: 50px;
  gap: 1px;
  /* width: 100%; */
  
}
.AboutUsContent {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  /* padding: 10px; */
}
.AboutUsContent p{
  text-align: left;
  flex: 1;
  padding: 3px;
}
.AboutUsContent img{
  height: 250px;
  max-width: 100%;
  padding: 30px;
}
  

.productSection{
  margin: 50px;
  padding-top: 50px;
  text-align: center;
}
.products{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 30px 0;
}
.product {
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0,0,0,.16);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
  width: 350px;
}
.product img{
  height: 250px;
  box-sizing: border-box;
  max-width: 100%;
}

.footer{
background-color: rgba(255, 255, 255, 255);
text-align: center;
padding: 32px;
/* height: 100px; */
}
.footer>p>.copyRight {
  color: #FFDC4B;
}
.footerNav{
  padding-top: 32px;
}
.footerNav>.navItem{
  color: #FFDC4B;
}