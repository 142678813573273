/* src/AboutUs.css */

.about-us {
    padding: 60px 20px;
    background-color: #f9f9f9;
   
}
.App-header{
    background-color: #012B7F;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
        padding: 30px;
}
.App-header h1{
    margin-bottom: 30px;
}
.AboutContainer {
    max-width: 1200px;
    margin: 50px;
    text-align: center;
}

.AboutContainer > h1,
h2 {
    color: #333;
    padding: 10px;
}

.AboutContainer p {
    color: #666;
    line-height: 1.6;
}

.mission-vision {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    padding: 32px;
}

.mission,
.vision {
    flex: 1;
    margin: 30px 20px;
}

.team {
    margin-top: 40px;
}

.team-members {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.team-member {
    text-align: center;
    margin: 20px;
}

.team-member img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.team-member h3 {
    margin-top: 10px;
    color: #333;
}

.team-member p {
    color: #777;
}

@media (max-width: 768px) {
    .mission-vision {
        flex-direction: column;
    }

    .mission,
    .vision {
        margin: 20px 0;
    }

    .team-members {
        flex-direction: column;
    }
}