/* src/Navbar.css */


.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F6F5F5 ;
    padding: 10px 20px;
    color: rgb(255, 220, 75);
   
}

.navbar-logo {
    font-size: 24px;
    font-weight: bold;
}

.navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    margin-left: 20px;
}

.navbar-links a {
    color: #012B7F;
    text-decoration: none;
    font-size: 18px;
}

.navbar-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
    /* background-color: rgba(1, 60, 174, 1); */
    border: none;
}

.bar {
    height: 3px;
    width: 25px;
    background-color: #000;
    margin: 4px 0;
    
}

@media (max-width: 768px) {
    .navbar-links {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
        background-color: #333;
        position: absolute;
        top: 60px;
        left: 0;
    }

    .navbar-links-mobile {
        display: flex;
        flex-direction: column;
        
    }

    .navbar-links-mobile .navItem {
        margin: 10px 0;
        color: #012B7F;
        text-decoration: none;
        font-size: 16px;
     
    }
        

    .navbar-toggle {
        display: flex;
    }
}